<template>
  <a-modal
    :title="handle === 'add' ? '新增' : handle === 'edit' ? '编辑' : '查看'"
    style="top: 8px"
    :width="1000"
    v-model="visible"
    :footer="null"
    :maskClosable="false"
  >
    <a-form-model ref="form" :model="rowData" :rules="formRule" layout="horizontal" :label-col="{span:3}" :wrapper-col="{span:21}">
      <a-form-model-item label="经销商全称" prop="name">
        <a-input :disabled="handle == 'check'" v-model="rowData.name" placeholder="请输入经销商全称"></a-input>
      </a-form-model-item>
      <!-- <a-form-model-item label="经销商简称" prop="simpleName">
        <a-input :disabled="handle == 'check'" v-model="rowData.simpleName" placeholder="请输入经销商简称"></a-input>
      </a-form-model-item> -->
      <a-form-model-item label="联系人" prop="contacts">
        <a-input :disabled="handle == 'check'" v-model="rowData.contacts" placeholder="请输入联系人"></a-input>
      </a-form-model-item>
      <a-form-model-item label="联系电话" prop="phone">
        <a-input :disabled="handle == 'check'" v-model="rowData.phone" placeholder="初始密码为手机号后六位"></a-input>
      </a-form-model-item>
      <a-form-model-item label="门店地址" prop="receiptInvoice">
        <PCD
          ref="pcd"
          :disabled="handle == 'check'"
          placeholder="请选择区域"
          :province.sync="rowData.province"
          :city.sync="rowData.city"
          :district.sync="rowData.county"
          :provinceId.sync="rowData.provinceId"
          :cityId.sync="rowData.cityId"
          :districtId.sync="rowData.countyId">
        </PCD>
      </a-form-model-item>
      <a-form-model-item label="详细地址" prop="address">
        <a-input :disabled="handle == 'check'" v-model="rowData.address" placeholder="请输入详细地址"></a-input>
      </a-form-model-item>
      <a-form-model-item label="所属大区及事务所" prop="">
        <a-cascader 
          :options="options"
          @change="onChange"
          placeholder="请选择"
          v-model="RegOff"
          :disabled="handle == 'check'"
          :field-names="{ label: 'name', value: 'id', children: 'firmVOS' }" />
      </a-form-model-item>
      <a-form-model-item label="经销商类型" prop="dealerType">
        <a-radio-group :disabled="handle == 'check'" :options="dealerOptions" v-model="rowData.dealerType" />
      </a-form-model-item>
      <a-form-model-item label="合作状态" prop="cooperateStatus">
        <a-radio-group :disabled="handle == 'check'" :options="consociationOptions" v-model="rowData.cooperateStatus" />
      </a-form-model-item>
      <a-form-model-item label="是否物优家店" prop="flagLinkgap">
        <a-switch :disabled="handle == 'check'" v-model="rowData.flagLinkgap" checked-children="是" un-checked-children="否" default-checked />
      </a-form-model-item>
      <a-form-model-item label="是否合作伙伴" prop="flagLinkgap">
        <a-switch :disabled="handle == 'check'" v-model="rowData.cooperativePartner" checked-children="是" un-checked-children="否" default-checked />
      </a-form-model-item>
      <a-form-model-item label="权限组" prop="templateId">
        <a-select placeholder="请选择权限组" v-model="rowData.templateId">
          <a-select-option :value="item.id" v-for="(item, index) in templateList" :key="index">{{item.name}}</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="账号" prop="account">
        <a-input :disabled="handle == 'check'" v-model="rowData.account" placeholder="请输入账号"></a-input>
      </a-form-model-item>
      <a-form-model-item label="初始密码" prop="password">
        <a-input :disabled="handle == 'check'" v-model="rowData.password" placeholder="请输入账号"></a-input>
      </a-form-model-item>
      <a-form-model-item label="纳税识别号" prop="taxCode">
        <a-input :disabled="handle == 'check'" v-model="rowData.taxCode" placeholder="纳税识别号"></a-input>
      </a-form-model-item>
      <a-form-model-item label="开户银行" prop="bank">
        <a-input :disabled="handle == 'check'" v-model="rowData.bank" placeholder="银行账号"></a-input>
      </a-form-model-item>
      <a-form-model-item label="银行账户" prop="bankAccount">
        <a-input :disabled="handle == 'check'" v-model="rowData.bankAccount" placeholder="银行账号"></a-input>
      </a-form-model-item>
      <a-form-model-item label="备注" prop="remark" :labelCol="{ span: 3 }" :wrapperCol="{ span: 21 }">
        <a-textarea :disabled="handle == 'check'" v-model="rowData.remark" placeholder="请输入"></a-textarea>
      </a-form-model-item>
    </a-form-model>
    <div class="footer-bts" v-if="handle != 'check'">
      <a-button key="back" @click="handleCancel">取消</a-button>
      <a-button key="submit" type="primary" :loading="loading" @click="toSubmit">提交</a-button>
    </div>
  </a-modal>
</template>
<script>

import { editCustomerInfo, selectByIdCustomerInfo, addCustomerInfo } from '../api/CustomerInfoApi'
export default {
  data() {
    return {
      visible: false,
      handle: 'add',
      rowData: {},
      loading: false,
      options: [],
      dealerOptions: [
        {
          value: 1, 
          label: '中心店'
        },
        {
          value: 2, 
          label: '核心店'
        },
        {
          value: 3, 
          label: '一般店'
        },
        {
          value: 4, 
          label: '核心店（预备）'
        },
      ],
      consociationOptions: [
        {
          value: 1,
          label: '合作中'
        },
        {
          value: 2,
          label: '合作终止'
        }
      ],
      RegOff: [],
      // 表单验证
      formRule: {
        fname: [
          {required: true, message: '请输入经销商全称', trigger: 'blur'}
        ],
        contacts: [
          {required: true, message: '请输入联系人', trigger: 'blur'}
        ],
        phone: [
          {required: true, pattern: new RegExp(/^1[3456789]\d{9}$/), message: '请输入正确的手机号', trigger: 'blur'}
        ],
        templateId: [
          {required: true, message: '请选择权限组', trigger: 'change'}
        ],
        bank: [
          {required: true, message: '请输入开户银行', trigger: 'blur'}
        ],
        bankAccount: [
          {required: true, message: '请输入银行账号', trigger: 'blur'}
        ],
        contactPerson: [
          { required: true, message: '请输入联系人', trigger: 'blur' }
        ],
        account: [
          { required: true, message: '请输入帐号', trigger: 'blur' }
        ],
        password: [
          { required: true, message: '请输入初始密码', trigger: 'blur' }
        ],
        taxCode: [
          { required: true, pattern: new RegExp(/^[0-9]{1,18}$/), message: '请输入纳税识别号', trigger: 'blur' }
        ],
      },
      templateList: [],
    }
  },
  methods: {
    // 获取行数据
    setRowData(row, handle) {
      this.handle = handle
      this.visible = true
      this.rowData = {}
      this.RegOff = []
      this.rowData.provinceId = ''
      this.$nextTick(() => {
        this.$refs.pcd.setPCD()
      })
      // 获取大区和事务所数据
      this.axios.get('/api/firm/mhiac/mhiacArea/areaFirmAll').then(res => {
        this.options = res.body
      })
      // 获取角色模板数据
      this.axios.get('/api/base/dealer/dealerTemplate/listAll').then(res => {
        this.templateList = res.body
      })
      if(handle == 'add') {
        this.$set(this.rowData, 'flagLinkgap', true)
        this.$set(this.rowData, 'dealerType', 1)
        this.$set(this.rowData, 'cooperateStatus', 1)
      }else if(handle == 'edit' || handle == 'check') {
        selectByIdCustomerInfo(row.id).then(res => { // 编辑或者查看
          this.rowData = res.body
          this.RegOff = [res.body.areaId, res.body.firmId]
          if(res.body) {
            this.$set(this.rowData, 'provinceId', res.body.provinceId)
            this.$set(this.rowData, 'province', res.body.province)
            this.$set(this.rowData, 'cityId', res.body.cityId)
            this.$set(this.rowData, 'city', res.body.city)
            this.$set(this.rowData, 'countyId', res.body.countyId)
            this.$set(this.rowData, 'county', res.body.county)
            this.$set(this.rowData, 'address', res.body.address)
            this.$nextTick(() => {
              this.$refs.pcd.setPCD()
            })
          }
        })
      }
    },
    // 关闭弹框
    handleCancel() {
      this.visible = false
    },
    onChange(val, item) {
      this.rowData.areaId = val ? val[0] : ''
      this.rowData.firmId = val ? val[1] : ''
      this.rowData.area = item ? item[0].name : ''
      this.rowData.firmName = item ? item[1].name : ''
    },
    // 表单提交
    toSubmit() {
      this.$refs.form.validate(async (valid) => {
        if (!valid) {
          return
        }
        if(!this.rowData.countyId) {
          return this.$message.warning('请选择门店地址')
        }
        const res = this.handle === 'add' ? await addCustomerInfo(this.rowData) : await editCustomerInfo(this.rowData)
        if (res.code === 200) {
          this.$notification.success({ message: res.message })
          this.$emit('reload')
          this.visible = false
        } else {
          this.$notification.error({ message: res.message })
        }
      })
    },
  },
  created() {

  },
}
</script>

<style lang="less" scoped>
.footer-bts {
  text-align: right;
  margin-top: 30px;
  padding-top: 20px;
  border-top: 1px solid #E9E9E9;
}
</style>
